import React from 'react';
import styled from 'styled-components';

import OrderSummaryItem from './OrderSummaryItem';
import OrderSummaryExtra from './OrderSummaryExtra';
import { formatPrice } from '../../helpers';

import theme from '../styled/theme';

const Header = styled.div`
  background: ${theme.colors.grayDark};
  color: white;
  font-family: 'Oneer';
  font-size: 1.25rem;
  margin: 0;
  padding: 0.5rem 1.5rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  h2 {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  background: #fdfdfc;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
`;

const Subtotal = styled.div`
  background: white;
  color: ${theme.colors.gray};
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.5rem 1rem;
`;

const Footer = styled.div`
  background: ${theme.colors.icecream};
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 1rem;
`;

const OrderSummary = props => {
  
  let extras = [];
  props.orderExtra.forEach((oe) => {
    if(oe.key === 'refresco' || oe.key === 'jugo') {
      let i = extras.findIndex(ex => ex.id === oe.id)
      if(i !== -1) {
        extras[i].quantity += 1
        return;
      }
    }
    extras.push(Object.assign({}, oe))
  })
  
  return (
    <Wrapper>
      <Header>
        <h2>Resumen de tu Orden</h2>
      </Header>
      <div>
        {props.order.map((order, index) => (
          <OrderSummaryItem key={index} data={order} removeItem={props.removeItem} canEdit={props.canEdit} history={props.history} totalOrder={props.order.length} />
        ))}
      </div>
      <div>
        {extras.map((order, index) => (
          <OrderSummaryExtra key={index} data={order} removeItemExtra={props.removeItemExtra} canEdit={props.canEdit} />
        ))}
      </div>
      <Subtotal>
        <div>ITBIS</div>
        <div>{formatPrice(props.tax)}</div>
      </Subtotal>
      {/* <Subtotal>
        <div>10% Delivery y desechables</div>
        <div>{formatPrice(props.serviceFee)}</div>
      </Subtotal> */}
      <Footer>
        <div>Total</div>
        <div>{formatPrice(props.total)}</div>
      </Footer>
    </Wrapper>
)};

export default OrderSummary;
