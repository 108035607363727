import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { AppContext } from "../AppProvider";

import Logo from "../Logo/";
import Header from "../Header";
import { ButtonLink, Layout } from "../styled";
import theme from "../styled/theme";

const Wrapper = styled.section`
  text-align: center;
  height: calc(100vh - 5rem);
`;

const Box = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(57, 56, 56, 0.35);
  margin: -2rem auto 0;
  max-width: 650px;
  padding: 2rem 0.5rem;
  position: relative;
  top: 15px;

  @media (min-width: 768px) {
    padding: 8rem 0;
    top: 50%;
    transform: rotate(-5deg) translateY(-35%);
  }
`;

const Title = styled.div`
  font-size: 3.5rem;
  line-height: 3.25rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 6rem;
    line-height: 4.75rem;
    margin-bottom: 3rem;
  }
`;

const Alert = styled.div`
  background: ${theme.colors.yellow};
  font-size: 1.5rem;
  padding: 1.5rem 2.5rem 1.5rem 3rem;
  position: relative;
  top: 1.5rem;
  left: 0;
  text-transform: uppercase;

  &::before {
    content: "!";
    color: ${theme.colors.primary};
    font-size: 4.5rem;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (min-width: 768px) {
    bottom: -5rem;
    width: 83%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledButton = styled(ButtonLink)`
  font-size: 3rem;
  padding: 1rem 3.5rem;
`;

const Home = () => {

  function setHomeText(context) {
    if(!context) return;
    if(!context.isRestaurantOpen) {
      if(context.account && context.account.closed_text) {
        return context.account.closed_text
      } else {
        return "NUESTRO DELIVERY SE ENCUENTRA CERRADO. LAS ORDENES SE RECIBEN EN HORARIO DE 11:00AM - 09:30PM (debido al toque de queda)"
      }
    } else {
      if(context.account && context.account.open_text) {
        return context.account.open_text
      } else {
        return "BIENVENIDOS AL SERVICIO DE DELIVERY Y TAKEOUT DE BAMBAN SUSHI. POR AHORA ESTA DISPONIBLE ORDENAR ARMANDO TU ROLLO, BOWL Y TEMAKI (CONO), BEBIDAS Y POSTRES DE LA CASA UTILIZANDO ESTA PLATAFORMA. CUANDO SE LE CONTACTE VIA WHATSAPP PODRA ORDENAR TARTAR, NIGUIRI Y SASHIMI."
      }
    }
  }
  return (
    <AppContext.Consumer>
      {(context) => (
        <div>
          <MediaQuery maxDeviceWidth={767}>
            <Header hideCart />
          </MediaQuery>
          <Layout>
            <Wrapper>
              <MediaQuery minDeviceWidth={768}>
                <Logo width="600px" />
              </MediaQuery>
              <Box>
                <Title>
                  ORDENA TU PLATO
                  <br />A TU MANErA!
                </Title>
                <StyledButton
                  bg="primary"
                  groovy={"groovy"}
                  to={!context.isRestaurantOpen ? '/' : "/ordenar/"}
                  disabled={!context.isRestaurantOpen}
                >
                  Empezar
                </StyledButton>

                {/* <Alert>
                  {setHomeText(context)}
                </Alert> */}
                <Alert>
                  {!context.isRestaurantOpen
                    ? "NUESTRO RESTAURANTE SE ENCUENTRA CERRADO. LAS ORDENES SE RECIBEN EN HORARIO DE 11:00AM - 09:30PM (debido al toque de queda)"
                    : // ? 'NUESTRO DELIVERY SE ENCUENTRA CERRADO.'
                      "BIENVENIDOS AL RESTAURANTE BAMBAN SUSHI. POR AHORA ESTA DISPONIBLE ORDENAR ARMANDO TU ROLLO, BOWL Y TEMAKI (CONO), BEBIDAS Y POSTRES DE LA CASA UTILIZANDO ESTA PLATAFORMA."}
                </Alert>
              </Box>
            </Wrapper>
          </Layout>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default Home;
