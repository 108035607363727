import React from 'react';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import { Container, Row, Column } from 'styled-bootstrap-components';

import { AppContext } from '../AppProvider';
import Header from '../Header';
import OrderSummary from '../OrderSummary';
import { Button, Layout } from '../styled';

const Title = styled.h2`
  font-family: 'Oneer';
  font-size: 2.25rem;
  margin: 0;
  text-transform: uppercase;
`;

const Wrapper = styled.section`
  background: white;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 2rem;
  word-break: break-word;
`;

const handleSubmit = (props, context) => {
  context.resetState();
  props.history.push('/');
};

const Confirmation = props => (
  <div>
    <MediaQuery maxDeviceWidth={767}>
      <Header hideCart />
    </MediaQuery>
    <Layout>
      <AppContext.Consumer>
        {context => (
          <Container>
            <Row>
              <Column sm={8}>
                <Wrapper>
                  <Title>Tu orden ha sido recibida!</Title>
                  <p> </p>
                  <p>Ya procedimos a elaborarla.</p>
                  <p>
                    Tómale un screenshot a tu orden.
                  </p>
                  <p>Si ordenaste alguna de nuestras bebidas, estaremos entregándotela primero, así como los postres de último; puedes aprovechar los acercamientos de nuestros camareros para cualquier solicitud, pregunta o comentario.</p>
                  <p>Tu orden estará lista y será llevada a tu mesa en los próximos 15 a 30 minutos, según como esté nuestra demanda.</p>
                  <p>Cuando desees pagar solicítalo con cualquiera de nuestro personal, el pago puede ser en efectivo, transferencia o en tarjeta de crédito.</p>
                  <p>Para cualquier interrogante, imprevisto o eventualidad puede contactarnos vía Whatsapp al 809-879-7427</p>
                  <br />
                  <Button bg="primary" mt="1rem" width="100%" onClick={() => handleSubmit(props, context)}>
                    Volver al inicio&raquo;
                  </Button>
                </Wrapper>
              </Column>
              <Column sm={4}>
                <OrderSummary
                  history={props.history}
                  order={context.order}
                  orderExtra={context.orderExtra}
                  total={context.total}
                  tax={context.tax}
                  serviceFee={context.serviceFee}
                  removeItem={context.removeItem}
                  removeItemExtra={context.removeItemExtra}
                />
              </Column>
            </Row>
          </Container>
        )}
      </AppContext.Consumer>
    </Layout>
  </div>
);

export default Confirmation;
