import React from 'react';
import styled, { css } from 'styled-components';
import MediaQuery from 'react-responsive';

import Ingredient from '../Ingredient';
import Wrapping from '../Ingredient/Wrapping';
import Temaki from '../Ingredient/Temaki';
import SectionHead from '../SectionHead';
import { Title, FlexRow, SectionLabel } from '../../styled';
import { seaweed } from '../../styled/variables';

import { proteins, sauces, complements, toppings } from '../../../util/ingredients';
import { wrappings, wrappingsSpecial, wrappingsExtraSpecial, temakis, bowls } from '../../../util/wrappings';
import Bowl from '../Ingredient/Bowl';
import {isBetweenDateTime} from '../../../helpers';

const Header = styled.div`
  position: relative;

  ${SectionLabel} {
    transform: rotate(-10deg);
    left: -2rem;
    min-width: 225px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    top: 1rem;
  }
`;

const Column = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  @media (max-width: 767px) {
    ${Column} {
      margin-bottom: 2rem;

      &:first-child {
        order: 2;
      }
      &:last-child {
        order: 3;
      }
    }
  }
`;

const List = styled.div`
  display: block;
  background: ${props => (props.background ? props.background : 'none')};
  box-shadow: ${props => (props.background ? '0 0 8px rgba(57, 56, 56, 0.25);' : '0')};
  border-radius: ${props => (props.background ? '5px' : '0')};
  padding: ${props => (props.padding ? props.padding : '0')};

  ${({ alternate }) =>
    alternate &&
    css`
      div:nth-child(odd) label > span {
        flex-grow: 1;
        text-align: right;
      }
      div:nth-child(even) label {
        flex-direction: row-reverse;

        > span {
          flex-grow: 1;
          text-align: left;
        }
      }
    `};

  ${({ reverse }) =>
    reverse &&
    css`
      div {
        text-align: right;

        label {
          flex-direction: row-reverse;
        }
      }
    `};
`;

const renderIngredientList = props => (
  <Ingredient
    key={props.data.key}
    id={props.data.key}
    data={props.data}
    background={props.background && props.data.key}
    image={!props.background && props.data.key}
    height={props.height}
    addIngredient={props.addIngredient}
    selectedData={props.selectedData}
  />
);

const renderWrappingList = props => (
  <Wrapping
    key={props.data.key}
    id={props.data.key}
    data={props.data}
    image={props.data.key}
    setWrapping={props.setWrapping}
    selectedData={props.selectedData}
    showPrice={props.showPrice}
  />
);

const IngredientList = props => (
  <div>
    <Header>
      <MediaQuery minDeviceWidth={768}>
        <SectionLabel background={seaweed} color="white">
          PLATO A TU MANErA
        </SectionLabel>
      </MediaQuery>
      <SectionHead description="Elige la envoltura" price="320">
        Rollo
      </SectionHead>
    </Header>
    <Wrapper>
      <Column>
        <List>
          <Title>Proteinas</Title>
          {proteins.map(data => renderIngredientList({ data, ...props, background: true, height: '96px' }))}
        </List>
        <Title>Salsas</Title>
        <List background="#A97256" alternate>
          {sauces.map(data => renderIngredientList({ data, ...props, height: '71px', background: false }))}
        </List>
      </Column>
      <Column>
        <List background="whitesmoke">
          {wrappings.map(data => renderWrappingList({ data, ...props, showPrice: false }))}

          <SectionHead description="(Sin Arroz)" small>
            Envoltura Especial
          </SectionHead>
          {wrappingsSpecial.map(data => renderWrappingList({ data, ...props }))}

          <SectionHead small>
            Rollo Empanizado
            <br />
          </SectionHead>
          {wrappingsExtraSpecial.map(data => renderWrappingList({ data: Object.assign(data, {
            closed: false
          }), ...props }))}
        </List>
        <List>
          <SectionHead subtitle="(Moro)" description="Elige las guarniciones" price="650">
            Bowl
          </SectionHead>
          <List background="whitesmoke">
            <FlexRow align="center">
              {/* <Bowl category="bowls" id="bowl" image="bowl" {...props} /> */}
              {bowls.map(bowl => <Bowl key={bowl.key} id={bowl.key} data={bowl} image={bowl.key} {...props} />)}
            </FlexRow>
          </List>
          <SectionHead subtitle="(Temaki)" description="Elige la envoltura" price="275">
            Cono
          </SectionHead>
          <List background="whitesmoke" padding="1rem">
            <FlexRow>
              {temakis.map(temaki => (
                <Temaki key={temaki.key} id={temaki.key} data={temaki} image={temaki.key} {...props} />
              ))}
            </FlexRow>
          </List>
        </List>
      </Column>
      <Column>
        <List reverse>
          <Title>Complementos</Title>
          {complements.map(data => renderIngredientList({ data, ...props, background: true, height: '92px' }))}
        </List>
        <div>
          <Title>Toppings</Title>
          <List background="#DB8C4D" reverse>
            {toppings.map(data => renderIngredientList({ data, ...props, height: '87px' }))}
          </List>
        </div>
      </Column>
    </Wrapper>
  </div>
);

export default IngredientList;
